import React from 'react'

const Contact = props => {
  return (
    <section id="contact">
      <div className="inner">
        <section>
          <form method="post" action="https://formspree.io/moqwayla">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="6"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input
                type="submit"
                value="Send Message"
                className="special"
              />
                {/* <button onClick={() => console.log('kkkk')}>
                  Send Message
                </button> */}
              </li>
              <li>
                <input type="reset" value="Clear" />
              </li>
            </ul>
          </form>
        </section>
        <section className="split">
          <section>
            <div className="contact-method">
              <span className="icon alt fa-envelope"></span>
              <h3>Email</h3>
              <a href="mailto:sales@getthebits.com">sales@getthebits.com</a>
            </div>
          </section>
          <section>
            <div className="contact-method">
              <span className="icon alt fa-phone"></span>
              <h3>Phone</h3>
              <span>+1 (786) 763-6548</span>
            </div>
          </section>
          <section>
            <div className="contact-method">
              <span className="icon alt fa-home"></span>
              <h3>Address</h3>
              <span>
                Downtown
                <br />
                Miami, FL
                <br />
                United States of America
              </span>
            </div>
          </section>
        </section>
      </div>
    </section>
  )
}

export default Contact
